.vertical-timeline {
  /* color: #828282; */
}
.vertical-timeline h1,
h2,
h3,
p,
span {
  color: inherit !important;
}
.vertical-timeline h1,
h2,
h3 {
  font-weight: 500;
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column {
  margin: 0 !important;
  margin-top: -20px !important;
  margin-left: 100px !important;
  padding-top: 100px !important;
}
.vertical-timeline-element-content {
  /* background: #2b364f; */
  padding-left: 2.5rem;
}
@media screen and (max-width: 768px) {
  .vertical-timeline-element-content {
    /* background: #2b364f; */
    padding-left: 1.5rem;
    border-right: 4px solid #89b7ff;
  }
}
.vertical-timeline-element-content p {
  font-family: GorditaRegular;
  color: #707070 !important;
}
span.vertical-timeline-element-date {
  color: #707070 !important;

}
.vertical-timeline-element-content .vertical-timeline-element-date{
  padding-top: 0;
  font-size: 1rem !important;
}
/*@media screen and (max-width: 768px) {
  .vertical-timeline-element-content .vertical-timeline-element-date{
    font-size: 1rem !important;
  }
}*/

.vertical-timeline-element-content::before {
  border-right-color: white;
}
@media screen and (max-width: 576px) {
  .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column {
    margin-left: -15px !important;
  }
}
@media screen and (max-width: 768px) {
  .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column {
    margin-left: -15px !important;
  }
}
@media screen and (max-width: 1300px) {
  .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column {
    margin-left: -15px !important;
  }
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column::before {
  background: #89b7ff !important;
}
.vertical-timeline-element-icon.bounce-in {
  background: #89b7ff !important;
  box-shadow: none;
}

.vertical-timeline-element-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
}

.vertical-timeline-element-date {
  font-size: 1.2rem !important;
  /* color: #828282; */
}
@media screen and (max-width: 768px) {
  .vertical-timeline{
    width: auto !important;
  }
}

