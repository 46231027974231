/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #4a5b75;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  max-width: calc(100% / 4);
}

/* General sidebar styles */
.bm-menu {
  background: transparent;
  padding: 2.5em 1.5em;
  overflow: hidden !important;
  padding-top: 315px;
  margin: -3rem 0;
  color: white;
}

@media screen and (max-height: 800px) {
  .bm-menu {
    padding-top: 250px;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #f5f6f8;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  /*padding: 0.8em;*/
}

/* Individual item */
.bm-item {
  display: inline-block !important;
  width: 100%;
}

/* Individual item */
button.bm-item {
  width: auto !important;
  transition: 0.2s ease-in-out 0.2s;
  border: 1px solid #89b7ff;
  background: transparent;
  color: #e2e2e2 !important;
}

/* Individual item */
button.bm-item:hover {
  /*background: #828282b3;*/
}

/* Styling of overlay */
.bm-overlay {
  opacity: 0 !important;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .bm-cross-button {
    height: 36px;
    width: 36px;
    display: block;
  }
  .bm-menu-wrap {
    max-width: 100%;
    background-image: url("../assets/menu_background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .bm-burger-button {
    right: 36px;
    display: block;
  }
  .bm-menu {
    padding-top: 0;
    overflow: auto !important;
    padding-right: 50px;
    margin-top: 50px;
  }

  .menu-icon {
    display: inline-block !important;
  }
}

.menu-icon {
  width: 1em;
  display: none;
  height: 1em;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: manipulation;
  cursor: pointer;
  position: fixed;
  z-index: 9999;
  left: calc(100vw - 3.5rem);
  top: 1rem;
  font-size: 2.5rem;
  transition: opacity 1s;
  opacity: 1;
  -webkit-tap-highlight-color: transparent;
}
.menu-icon:not(.isOpen).menu-hidden{
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.menu-icon span {
  display: block;
  position: absolute;
  top: 50%;
  left: calc((1em - 0.75em) / 2);
  background-color: #ffffff;
  width: 0.75em;
  height: 0.05em;
  border-radius: 0;
  margin-top: calc(0.05em / -2);
}

.menu-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: calc((1em - 0.75em) / 2);
  background-color: #ffffff;
  width: 0.75em;
  height: 0.05em;
  border-radius: 0;
  margin-top: calc(-0.05em * 5 + (0.05em / -2));
}

.menu-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: calc((1em - 0.75em) / 2);
  background-color: #ffffff;
  width: 0.75em;
  height: 0.05em;
  border-radius: 0;
  margin-top: calc(0.05em * 5 + (0.05em / -2));
}

.burger span {
  transition: 0.2s ease-in-out 0.2s;
}

.burger:before,
.burger:after {
  transition: margin 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s;
}

.burger.isOpen span {
  background-color: #ffffff00;
  transition-delay: 0s;
}

.burger.isOpen:before,
.burger.isOpen:after {
  margin-top: 0;
  transition-delay: 0s, 0.25s, 0.25s;
}

.burger.isOpen:before {
  transform: rotate(45deg);
}

.burger.isOpen:after {
  transform: rotate(-45deg);
}
