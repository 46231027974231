.rc-slider {
  margin: 2rem 0;
  align-self: flex-end !important;
}

.rc-slider-rail,
.rc-slider-step {
  background-color: #828282;
}

.rc-slider-handle {
  transform: scale(2.5);
  background-color: #89b7ff;
  border: none;
}

.euro:before {
  content: "€ ";
  position: absolute;
  top: 34%;
  left: 0px;
  font-size: 36px;
  font-family: GorditaRegular;
  color: #fff;
}

.euro {
  position: relative;
}

@media screen and (max-width: 1300px) {
  .euro:before {
    top: 33%;
    left: -4px;
    font-size: 25px;
  }
}
